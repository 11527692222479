<template>

	<div class="wrap p-learning-status">
		<!-- header -->
		<header class="header-step">
			<div class="head-left">
				<button type="button" class="btn-back" @click="$router.back()"><span class="blind">이전 페이지</span></button>
			</div>
			<div class="head-center">
				<div class="head-tit">
					<span class="page-tit">학습현황</span>
				</div>
			</div>
			<div class="head-right"></div>
		</header>
		<!-- container -->
		<div class="container">
			<div class="content">
				<div class="status-head">
					<div class="learn-path">
						<select name="" id="" v-model="cidx" @change="selectCourse">
							<option :value="item.cidx" v-for="(item, index) in courseList">{{ item.shortTitle }}</option>
						</select>
						<select name="" id="" v-model="emIdx" v-if="mainList.length > 0" @change="selectMain">
							<option :value="item.emIdx" v-for="(item, index) in mainList" >{{ item.shortTitle }}</option>
						</select>
						<strong v-if="eduMainVO != null">{{ eduMainVO.eduTitle}}</strong>
					</div>

					<div class="learn-progress">
						<!-- 진행에 따라 active 추가 -->
						<i v-for="num in guageMax" :class="{ 'active': (num <= guageNum) }"></i>
						<span class="per">{{ guagePercen }}</span>
					</div>

				</div>
				<div class="status-cont" v-if="eduMainVO != null">
					<ul class="learn-list">
						<li v-for="(item, index) in eduMainVO.contentList">

							<!-- 영상 -->
							<template v-if="item.contentType === 'mov'">
								<div class="learn-item">
									<div class="thumb" :style="'background-image: url(' + item.qstMovVO.movThumb + ')'"></div>
									<strong class="tit">{{ item.qstMovVO.movTitle }}</strong>
								</div>
								<span class="date">{{ item.recordDate | dateFilter }}&nbsp;</span>
								<span class="view">{{ item.recordCount }}</span>
							</template>
							<!-- 테스트 -->
							<template v-if="item.contentType === 'tst'">
								<div class="learn-item">
									<div class="thumb" style="background-image: url(/assets/images/learning/img_book_quiz.png)"></div>
									<strong class="tit">Report</strong>
								</div>
								<span class="date">{{ item.recordDate | dateFilter }}&nbsp;</span>
								<span class="view">{{ item.recordCount }}</span>
							</template>

						</li>

						<li v-if="eduMainVO.contentList.length <= 0">해당 내역이 없습니다.</li>

					</ul>
				</div>
			</div>
		</div>
	</div>

</template>

<script>
export default {
	name: "myEducation",
	data () {

		return {
			params: this.pageUtils.Params("shortTitle,ASC"),
			cidx: null,
			emIdx: null,

			courseList: [],
			mainList: [],
			eduMainVO: null,

			recordCount: 0,
			recordList: [],

			// 최신 교육내용 조회
			guageNum: 0,
			guageMax: 7,
			guagePercen: 0,
			recentAnswer: null,
		}
	},
	created() {
		this.getOpenCourse();
		this.getLastStep();
	},
	mounted() {
	},
	methods: {

		/**
		 * 사용자의 열린 교과정 조회
		 */
		getOpenCourse() {

			this.$eventBus.$emit("startProgress")

			const self = this

			this.params.openPrevCourse = false
			this.params.openPrevMain = true
			this.params.cateCode = "200101"
			this.axiosCaller.get(self, this.APIs.EDU_COURSE + "/getOpenCourseList", this.params, (res) => {

				const result = res.data
				if (result.status === "ok") {

					this.courseList = result.list
					for (let i = this.courseList.length - 1; i >= 0; i--) {
						if ( this.courseList[i].isOpen ) {
							this.courseList[i].isActive = true;
							this.cidx = this.courseList[i].cidx
							this.mainList = this.courseList[i].eduMainList
							break;
						}
					}

				} else {
					this.swalUtils.gritter(result.status, result.msg, "error")
				}

				this.$eventBus.$emit("doneProgress")
			})

		},





		/**
		 * 사용자의 최근 답변 데이터 조회
		 */
		getLastStep() {

			const self = this
			this.axiosCaller.get(this, this.APIs.EDU_MINE + "/getLastStep", {
				userId: this.getLoginId(),
				cateCode: "200101"
			}, res => {
				const result = res.data
				if (result.status === "ok") {

					self.recentAnswer = {
						answerList: result.answerList,
						eduCourseVO: result.eduCourseVO,
						eduMainVO: result.eduMainVO,
						contentList: result.eduMainVO.contentList,
					}

					self.cidx = result.eduCourseVO.cidx;
					self.emIdx = result.eduMainVO.emIdx;
					self.contentList = result.eduMainVO.contentList;
					self.getEduMain( self.emIdx );
				}
			})
		},




		getMainStep( _emIdx ) {

			const self = this

			self.guageNum = 0;
			self.guagePercen = 0;

			this.axiosCaller.get(this, this.APIs.EDU_MINE + "/getMainStep", {
				emIdx: _emIdx,
				userId: this.getLoginId()
			}, res => {
				const result = res.data
				if (result.status === "ok") {

					self.recentAnswer = {
						answerList: result.answerList,
						eduCourseVO: result.eduCourseVO,
						eduMainVO: result.eduMainVO,
						contentList: result.eduMainVO.contentList,
					}

					if (result.answerList !== undefined && result.answerList.length > 0) {

						for (let i = 0; i < self.recentAnswer.answerList.length; i++) {
							const answer = self.recentAnswer.answerList[i];
							if (answer.isComplete) {
								if (self.recentAnswer.contentList[i].qstMovVO != null) {

									if (self.recentAnswer.contentList[i].qstMovVO.movTitle.toLowerCase().indexOf("week") > -1) {
										self.guagePercen += 15;
									} else {
										self.guagePercen += 10;
									}

								} else {
									if ( answer.ansTstList !== undefined && answer.ansTstList.length > 0) {
										self.guagePercen += 20;
									}
								}
								self.guageNum++;
							}
						}
					}

				}
			})
		},



		selectCourse( e ) {
			this.courseList.forEach(course => {
				if ( course.cidx === parseInt( e.target.value ) ) {
					this.mainList = course.eduMainList
				}
			})

			if ( this.mainList.length > 0 ) {
				this.emIdx = this.mainList[0].emIdx
				this.getEduMain( this.emIdx );
			}
		},


		selectMain( e ) {
			this.getEduMain( e.target.value );
		},


		getEduMain( _emIdx ) {
			const self = this
			self.axiosCaller.get(self, self.APIs.EDU_MAIN + "/getEduMain4Records", {
				emIdx: _emIdx,
				userId: this.getLoginId(),
				recordMode: "record",
			}, res => {
				const result = res.data
				if ( result.status === "ok" ) {
					self.eduMainVO = result.eduMainVO
					// self.eduMainVO.contentList = result.contentList

					self.getMainStep( self.eduMainVO.emIdx );
				}
			})
		},


	},
	watch: {

		/*emIdx (newVal) {
			this.getRecords( newVal )
			this.mainList.forEach(val => {
				if ( val.emIdx == newVal ) {
					this.eduMainVO = val
				}
			})
		}*/

	}
}
</script>

<style scoped>

</style>